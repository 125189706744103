<template>
  <div class="refill-new">
    <div class="font-weight-bold mb-3">
      <router-link class="disabled--text" to="/refill-codes">Digital Wallet refill code</router-link>
      <v-icon color="disabled" class="text-h6">mdi-chevron-right</v-icon>
      <span class="black--text">Create new refill code</span>
    </div>
    <v-card outlined min-height="70vh" color="d-flex flex-column">
      <div class="pl-lg-9 px-sm-7 pt-sm-7 pt-3 px-3 item-content">
        <div class="d-flex flex-md-row flex-column">
          <div class="me-md-3 width50">
            <div class="font-weight-bold mb-3">Refill code</div>
            <v-text-field
              class="field46"
              v-model="data.code"
              placeholder="Enter code"
              :error-messages="codeErrors"
              outlined
              dense
              required
              color="primary"
              height="46"
            ></v-text-field>
          </div>
          <div class="ms-md-3 width50">
            <div class="font-weight-bold mb-3">Usage limit</div>
            <v-text-field
              class="field46"
              v-model="data.usage_limit"
              placeholder="Enter usage limit"
              :error-messages="limitErrors"
              type="number"
              hint="0 for unlimited usage"
              persistent-hint
              outlined
              dense
              required
              color="primary"
              height="46"
            ></v-text-field>
          </div>
        </div>
        <div class="font-weight-bold mb-3">Code group</div>
        <v-select
          class="field46"
          v-model="data.group"
          placeholder="Select"
          :error-messages="groupErrors"
          :items="itemsGroup"
          item-text="name"
          item-value="id"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-select>
        <div class="d-flex align-center v-input mt-n3 mb-3">
          <div class="text-caption gray--text">You can create new group for refill codes</div>
          <v-spacer></v-spacer>
          <v-btn @click="modal = true" text color="primary lighten-1" x-small><span class="text-caption">+ Add new</span></v-btn>
        </div>
        <div class="font-weight-bold mb-3">Amount</div>
        <v-text-field
          class="field46"
          v-model="data.amount"
          placeholder="Enter amount"
          :error-messages="amountErrors"
          type="number"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-text-field>
        <div class="font-weight-bold mb-3">Availability dates</div>
        <div class="d-flex flex-md-row flex-column v-input">
          <vc-date-picker
            locale="en"
            :input-debounce="500"
            v-model="data.available_since"
            :masks="masks"
            :model-config="modelConfig"
            :first-day-of-week="2"
            is-required
            :min-date="new Date()"
            color="blue"
            class="me-md-3"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <v-text-field
                class="field46"
                :value="inputValue"
                v-on="inputEvents"
                placeholder="From"
                :error-messages="fromErrors"
                outlined
                dense
                color="primary"
                height="46"
                readonly
              >
                <template v-slot:append>
                  <v-img class="mt-2" src="@/assets/icon/calendar.svg"></v-img>
                </template>
              </v-text-field>
            </template>
          </vc-date-picker>
          <vc-date-picker
            locale="en"
            :input-debounce="500"
            v-model="data.available_till"
            :masks="masks"
            :model-config="modelConfig"
            :first-day-of-week="2"
            is-required
            :min-date="data.available_since || new Date()"
            color="blue"
            class="ms-md-3"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <v-text-field
                class="field46"
                :value="inputValue"
                v-on="inputEvents"
                placeholder="To"
                :error-messages="toErrors"
                outlined
                dense
                color="primary"
                height="46"
                readonly
              >
                <template v-slot:append>
                  <v-img class="mt-2" src="@/assets/icon/calendar.svg"></v-img>
                </template>
              </v-text-field>
            </template>
          </vc-date-picker>
        </div>
      </div>
      <v-spacer></v-spacer>
      <v-divider></v-divider>
      <div class="text-end py-3 px-sm-10 px-3">
        <v-btn @click="createItem" depressed class="rounded primary text-body-2" height="30" width="106">Create</v-btn>
      </div>
    </v-card>
    <v-dialog v-model="modal" max-width="460">
      <v-card flat outlined class="pa-8 pt-5">
        <div class="text-h6 font-weight-bold mb-5">Add new group</div>
        <v-text-field
          class="field46"
          v-model="title"
          placeholder="Enter title"
          :error-messages="titleErrors"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-text-field>
        <div class="text-end mt-2">
          <v-btn @click="createGroup" depressed class="primary rounded text-body-2" width="106" height="30">Create</v-btn>
          <v-btn @click="modal = false" depressed class="ms-3 rounded" outlined width="106" height="30" text color="gray lighten-1">
            <span class="black--text text-body-2">Cancel</span>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      modal: false,
      data: {},
      masks: {
        input: 'MMM D, YYYY',
      },
      modelConfig: {
        type: 'number',
        mask: 'iso',
      },
      title: '',
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getRefillCodeGroup');
  },
  methods: {
    notifi(btn) {
      if (btn == 'done') {
        this.$notify({
          title: 'Done!',
          message: 'Digital Wallet refill code was successfully created',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'error') {
        this.$notify({
          title: 'Something went wrong!',
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    async createGroup() {
      this.error = [];
      await this.$store
        .dispatch('createRefillCodeGroup', { name: this.title })
        .then(() => {
          this.modal = false;
        })
        .catch((e) => {
          this.error = e.response.data.error;
          e.response.status > 401 ? this.notifi('error') : '';
        });
    },
    async createItem() {
      this.error = [];
      this.data.amount = this.data.amount * 100;
      await this.$store
        .dispatch('createRefillCode', this.data)
        .then(() => {
          this.notifi('done');
        })
        .catch((e) => {
          this.data.amount = this.data.amount / 100;
          this.error = e.response.data.error;
          e.response.status > 401 ? this.notifi('error') : '';
        });
    },
  },
  watch: {
    modal() {
      this.title = '';
      this.error = [];
    },
  },
  computed: {
    itemsGroup() {
      return this.$store.getters.refillCodeGroup;
    },
    titleErrors() {
      const errors = [];
      this.error.find((item) => item == 'title__required') && errors.push('Please enter title');
      this.error.find((item) => item == 'title__invalid') && errors.push('Provided title is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    codeErrors() {
      const errors = [];
      this.error.find((item) => item == 'code__required') && errors.push('Please enter code');
      this.error.find((item) => item == 'code__invalid') && errors.push('Provided code is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    limitErrors() {
      const errors = [];
      this.error.find((item) => item == 'usage_limit__required') && errors.push('Please enter usage limit');
      this.error.find((item) => item == 'usage_limit__invalid') && errors.push('Provided usage limit is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    groupErrors() {
      const errors = [];
      this.error.find((item) => item == 'group__required') && errors.push('Please select group');
      this.error.find((item) => item == 'group__invalid') && errors.push('Selected group is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    amountErrors() {
      const errors = [];
      this.error.find((item) => item == 'amount__required') && errors.push('Please enter amount');
      this.error.find((item) => item == 'amount__invalid') && errors.push('Provided amount is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    fromErrors() {
      const errors = [];
      this.error.find((item) => item == 'available_since__required') && errors.push('Please enter available since');
      this.error.find((item) => item == 'available_since__invalid') && errors.push('Provided available since is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    toErrors() {
      const errors = [];
      this.error.find((item) => item == 'available_till__required') && errors.push('Please enter available till');
      this.error.find((item) => item == 'available_till__invalid') && errors.push('Provided available till is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setRefillCodeGroup');
  },
};
</script>

<style lang="scss">
#app .refill-new {
  .v-input {
    max-width: 371px;
  }
}
</style>
